import React from "react"

export interface Props {
  children?: React.ReactNode
  className?: string
  refProp?: any
  onclick?: (e?: any) => void
}

const Modal = ({ children, className, refProp, onclick }: Props) => {
  return (
    <div className="sova-section-container sova-section-modal_modalContainer" ref={refProp} onClick={onclick}>
      <div className={`sova-section-container sova-section-modal_modalView${className ? ` ${className}` : ""}`}>{children}</div>
    </div>
  )
}

Modal.displayName = "Modal"

export default Modal
