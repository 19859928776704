import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../../../components/section/Section"

const ArticleSectionFourth = ({ className, data }: { className: string; data: any }) => {
  const otherPosts = useStaticQuery(
    graphql`
      query {
        allStrapiArticle(limit: 3) {
          edges {
            node {
              content {
                content
                id
                language
                previewText
                title
                author {
                  name
                  position
                  short_bio
                  authorImg {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                blog_tags {
                  name
                }
                previewImg {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                link
              }
              created_at(formatString: "lll")
            }
          }
          totalCount
        }
      }
    `
  )

  const { lang, locale } = data.data.pageContext

  const posts = otherPosts.allStrapiArticle.edges.map((el: any) => {
    const content = el.node.content.map((elem: any) => {
      return { ...elem, date: el.node.created_at }
    })
    return content.find((elem: any) => {
      return elem.language === lang
    })
  })

  return (
    <Section className={`${className}`}>
      <p className="sova-section-child sova-h2 sova-h2_blog-heading">Read also</p>
      <div className="sova-section-container sova-section-container_row sova-section-container_article-postList">
        {posts.map((post: any, key: number) => (
          <Link to={`/blog${locale + post.link}`} key={key} className={`sova-card sova-card_article-postListItem`}>
            <div className={`sova-section-container sova-section-container_blog-postContainer-${key}`}>
              {post.previewImg && <Img fluid={post.previewImg.childImageSharp.fluid} className="sova-section_blog-postImg sova-section_blog-postImg_article" />}
              <div className="sova-section-container sova-section-container_blog-text">
                {post.blog_tags.map(
                  (tag: any, key: number) =>
                    key === 0 && (
                      <p className="sova-section-child sova-section-child_blog sova-section-child_blog-postTag sova-p-tiny" key={key}>
                        {tag.name}
                      </p>
                    )
                )}
                <h3 className="sova-section-child sova-h3 sova-section-child_blog sova-section-child_blog-postTitle">{post.title}</h3>
                <p className="sova-section-child sova-section-child_blog-postPreview sova-p-tiny">{post.previewText}</p>
                <div className="sova-section-container sova-section-container_row sova-section-container_blog-authorInfo">
                  {post.author.authorImg && <Img fluid={post.author.authorImg.childImageSharp.fluid} className={`sova_blog-authorImg sova_blog-authorImg-${className}-${key}`} />}
                  <div className="sova-section-container sova-section-container_blog-authorInfo-text">
                    <p className="sova-p-tiny">{post.author.name}</p>
                    <p className="sova-p-tiny">{post.date}</p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Section>
  )
}

ArticleSectionFourth.displayName = "ArticleSectionFourth"

export default ArticleSectionFourth
