import React from "react"

import ArticleSectionFirst from "./articleSections/articleSectionFirst"
import ArticleSectionSecond from "./articleSections/articleSectionSecond"
// import ArticleSectionThird from "./articleSections/articleSectionThird"
import ArticleSectionFourth from "./articleSections/articleSectionFourth"

const sections = [
  { component: (key: number, className: string, data: any) => <ArticleSectionFirst data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <ArticleSectionSecond data={data} key={key} className={className} /> },
  // { component: (key: number, className: string, data: any) => <ArticleSectionThird data={data} key={key} className={className} /> },
  { component: (key: number, className: string, data: any) => <ArticleSectionFourth data={data} key={key} className={className} /> },
]

const ArticleSections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section_article  sova-section_article_${i}`, data))}</>
}

ArticleSections.displayName = "ArticleSections"

export default ArticleSections
