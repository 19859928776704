const syntaxTheme = {
  'code[class*="language-"]': {
    color: "white",
    background: "none",
    fontFamily: "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
    textAlign: "left",
    whiteSpace: "pre-wrap",
    wordSpacing: "normal",
    wordBreak: "normal",
    wordWrap: "normal",
    lineHeight: "1.5",
    MozTabSize: "4",
    OTabSize: "4",
    tabSize: "4",
    WebkitHyphens: "none",
    MozHyphens: "none",
    msHyphens: "none",
    hyphens: "none",
  },
  'pre[class*="language-"]': {
    color: "black",
    background: "#0F1F48",
    fontFamily: "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
    textAlign: "left",
    whiteSpace: "pre-wrap",
    wordSpacing: "normal",
    wordBreak: "normal",
    wordWrap: "normal",
    lineHeight: "1.5",
    MozTabSize: "4",
    OTabSize: "4",
    tabSize: "4",
    WebkitHyphens: "none",
    MozHyphens: "none",
    msHyphens: "none",
    hyphens: "none",
    position: "relative",
    margin: ".5em 0",
    overflow: "visible",
    backgroundColor: "none",
    WebkitBoxSizing: "border-box",
    MozBoxSizing: "border-box",
    boxSizing: "border-box",
    marginBottom: "1em",
    borderRadius: ".25rem",
    padding: "1.25rem",
  },
  'pre[class*="language-"]>code': {
    position: "relative",
    borderLeft: "10px solid #358ccb",
    boxShadow: "-1px 0px 0px 0px #358ccb, 0px 0px 0px 1px #dfdfdf",
    backgroundColor: "none",
    backgroundImage: "linear-gradient(transparent 50%, rgba(69, 142, 209, 0.04) 50%)",
    backgroundSize: "3em 3em",
    backgroundOrigin: "content-box",
    backgroundAttachment: "local",
  },
  'code[class*="language"]': {
    maxHeight: "inherit",
    height: "inherit",
    padding: "0 1em",
    display: "block",
    overflow: "auto",
  },
  ':not(pre) > code[class*="language-"]': {
    backgroundColor: "none",
    WebkitBoxSizing: "border-box",
    MozBoxSizing: "border-box",
    boxSizing: "border-box",
    marginBottom: "1em",
    position: "relative",
    padding: ".2em",
    borderRadius: "0.3em",
    color: "red",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    display: "inline",
    whiteSpace: "normal",
  },
  'pre[class*="language-"]:before': {
    content: "''",
    zIndex: "-2",
    display: "block",
    position: "absolute",
    bottom: "0.75em",
    left: "0.18em",
    width: "40%",
    height: "20%",
    maxHeight: "13em",
    boxShadow: "0px 13px 8px #979797",
    WebkitTransform: "rotate(-2deg)",
    MozTransform: "rotate(-2deg)",
    msTransform: "rotate(-2deg)",
    OTransform: "rotate(-2deg)",
    transform: "rotate(-2deg)",
  },
  'pre[class*="language-"]:after': {
    content: "''",
    zIndex: "-2",
    display: "block",
    position: "absolute",
    bottom: "0.75em",
    left: "auto",
    width: "40%",
    height: "20%",
    maxHeight: "13em",
    boxShadow: "0px 13px 8px #979797",
    WebkitTransform: "rotate(2deg)",
    MozTransform: "rotate(2deg)",
    msTransform: "rotate(2deg)",
    OTransform: "rotate(2deg)",
    transform: "rotate(2deg)",
    right: "0.75em",
  },
  ':not(pre) > code[class*="language-"]:after': {
    right: "0.75em",
    left: "auto",
    WebkitTransform: "rotate(2deg)",
    MozTransform: "rotate(2deg)",
    msTransform: "rotate(2deg)",
    OTransform: "rotate(2deg)",
    transform: "rotate(2deg)",
  },
  comment: {
    color: "#7D8B99",
  },
  "block-comment": {
    color: "#7D8B99",
  },
  prolog: {
    color: "#7D8B99",
  },
  doctype: {
    color: "#82AAFF",
  },
  cdata: {
    color: "#7D8B99",
  },
  punctuation: {
    color: "#5F6364",
  },
  property: {
    color: "#82AAFF",
  },
  tag: {
    color: "#82AAFF",
  },
  boolean: {
    color: "#82AAFF",
  },
  number: {
    color: "#c92c2c",
  },
  "function-name": {
    color: "#c92c2c",
  },
  constant: {
    color: "#82AAFF",
  },
  symbol: {
    color: "#c92c2c",
  },
  deleted: {
    color: "#c92c2c",
  },
  selector: {
    color: "#82AAFF",
  },
  "attr-name": {
    color: "green",
  },
  string: {
    color: "#a6d003",
  },
  char: {
    color: "#82AAFF",
  },
  function: {
    color: "#82AAFF",
  },
  builtin: {
    color: "#82AAFF",
  },
  inserted: {
    color: "red",
  },
  operator: {
    color: "#ffffff",
    background: "none",
  },
  entity: {
    color: "#a67f59",
    background: "none",
    cursor: "help",
  },
  url: {
    color: "#a67f59",
    background: "none",
  },
  variable: {
    color: "#a67f59",
    background: "none",
  },
  atrule: {
    color: "#1990b8",
  },
  "attr-value": {
    color: "#1990b8",
  },
  keyword: {
    color: "#1990b8",
  },
  "class-name": {
    color: "#1990b8",
  },
  regex: {
    color: "#e90",
  },
  important: {
    color: "#e90",
    fontWeight: "normal",
  },
  ".language-css .token.string": {
    color: "red",
    background: "none",
  },
  ".style .token.string": {
    color: "red",
    background: "none",
  },
  bold: {
    fontWeight: "bold",
  },
  italic: {
    fontStyle: "italic",
  },
  ".namespace": {
    Opacity: ".7",
  },
  "tab:not(:empty):before": {
    color: "red",
  },
  "cr:before": {
    color: "#e0d7d1",
  },
  "lf:before": {
    color: "#e0d7d1",
  },
  'pre[class*="language-"].line-numbers.line-numbers': {
    paddingLeft: "0",
  },
  'pre[class*="language-"].line-numbers.line-numbers code': {
    paddingLeft: "3.8em",
  },
  'pre[class*="language-"].line-numbers.line-numbers .line-numbers-rows': {
    left: "0",
  },
  'pre[class*="language-"][data-line]': {
    paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: "0",
  },
  "pre[data-line] code": {
    position: "relative",
    paddingLeft: "4em",
  },
  "pre .line-highlight": {
    marginTop: "0",
  },
}

export default syntaxTheme
