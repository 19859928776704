import React from "react"
import Img from "gatsby-image"
import Section from "../../../components/section/Section"

const ArticleSectionFirst = ({ className, data }: { className: string; data: any }) => {
  return <Section className={`${className}`}>{data.data.mainImg && <Img fluid={data.data.mainImg.childImageSharp.fluid} className="sova-blog-mainImg" />}</Section>
}

ArticleSectionFirst.displayName = "ArticleSectionFirst"

export default ArticleSectionFirst
