import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import { Link, navigate } from "gatsby"
import Img from "gatsby-image"

import { useStoreon } from "../../../store/store"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Section from "../../../components/section/Section"
import codeSyntaxHighlighter from "../../../../notused/docs/components/code/syntaxHighlighter"
import Modal from "../../../components/modal/Modal"

import closeIcon from "../../../assets/icons/close-icon-sova.ai.svg"

import mediumIcon from "../../../blog/assets/socialIcons/medium-icon-for-blog-sova.ai.svg"
import gitHubIcon from "../../../blog/assets/socialIcons/gitHub-icon-for-blog-sova.ai.svg"
import facebookIcon from "../../../blog/assets//socialIcons/facebook-icon-for-blog-sova.ai.svg"
import youtubeIcon from "../../../blog/assets/socialIcons/youtube-icon-for-blog-sova.ai.svg"
import twitterIcon from "../../../blog/assets/socialIcons/twitter-icon-for-blog-sova.ai.svg"
import rightIcon from "../../../blog/assets/icons/right-icon.svg"

const ArticleSectionSecondConfig = {
  socialNetworksIcons: [
    {
      icon: facebookIcon,
      link: "https://www.facebook.com/hello.sova",
    },
    {
      icon: youtubeIcon,
      link: "https://www.youtube.com/channel/UCLJtz3snnlYheeJM4Ox5CrQ",
    },
    {
      icon: mediumIcon,
      link: "https://medium.com/sova-ai",
    },
    {
      icon: twitterIcon,
      link: "https://twitter.com/hello_sova",
    },
    {
      icon: gitHubIcon,
      link: "https://github.com/sovaai",
    },
  ],
}

const ArticleSectionSecond = ({ className, data }: { className: string; data: any }) => {
  const { dispatch } = useStoreon()
  const { socialNetworksIcons } = ArticleSectionSecondConfig
  const [mail, changeMailValue] = useState("")
  const [mailValidation, changeMailValidationMsg] = useState("")
  const [mailValidationStatus, changeMailValidationStatus] = useState("")

  const { content, author, tags, title, postParentUrl, postParent } = data.data
  const { locale } = data.data.pageContext

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const subscribe = (e: any) => {
    e.preventDefault()
    addToMailchimp(mail, { "group[78322][1]": "subscription" })
      .then((data: any) => {
        changeMailValue("")
        changeMailValidationMsg(data.msg)
        changeMailValidationStatus(data.result)
      })
      .catch(() => {
        throw Error
      })
  }

  return (
    <Section className={`${className} sova-section-without-margin-bottom`}>
      <div className="sova-section-container sova-section-container_row sova-section-container_article-breadcrumbs">
        <Link to={`${locale + postParentUrl}`} className="sova-section-child sova-section-child_article-breadcrumbsLink">
          {postParent}
        </Link>
        <img src={rightIcon} className="sova-icon sova-icon_article-breadcrumbsIcon" />
        <p className="sova-section-child sova-section-child_article-breadcrumbsPostTitle">{title}</p>
      </div>
      <div className="sova-section-container sova-section-container_row sova-section-container_article">
        <div className="sova-section-container sova-section-container_article-leftPanel">
          {socialNetworksIcons.map(({ icon, link }, key) => (
            <a href={link} key={key} className="sova-section-child sova-section-child_article-socialIcon">
              <img src={icon} className="sova-section-img" />
            </a>
          ))}
        </div>
        <div className="sova-section-container sova-section-container_article-centralPanel">
          <div className="sova-section-container">
            <ReactMarkdown source={content} className="sova-section-container_markdown sova-section-container_markdown-article" renderers={{ code: codeSyntaxHighlighter }} />
          </div>
        </div>
        <div className="sova-section-container sova-section-container_article-rightPanel">
          <div className="sova-section-container">
            <div className="sova-section-container sova-section-container_row sova-section-container_article-authorInfo">
              {author.authorImg && <Img fluid={author.authorImg.childImageSharp.fluid} className="sova_blog-authorImg sova_blog-authorImg-article" />}
              <div className="sova-section-container sova-section-container_article-authorName">
                <p>{author.name}</p>
                <p className="sova-section-child sova-p-tiny_article sova-p-tiny_article-authorPosition">{author.position}</p>
              </div>
            </div>
            <p className="sova-section-child sova-section-child_article-authorBio sova-p-tiny_article>">{author.short_bio}</p>
            {/* <div>
              <h5 className="sova-section-child sova-section-child_article-formHeading sova-section-child_article-form">Join our Newsletter</h5>
              <p className="sova-section-child sova-section-child_article-form-text sova-section-child_article-form">Stay up to date with the latest news from the SOVA community</p>
              <div className="sova-section-container">
                <input type="email" className="sova-input sova-input_article sova-p-tiny" placeholder="E-mail" value={mail} onChange={e => changeMailValue(e.target.value)} />
                <button className="sova-btn sova-btn_article sova-btn_secondary sova-btn_small" onClick={e => subscribe(e)}>
                  Subscribe
                </button>
              </div>
            </div> */}
          </div>
          <div className="sova-section-container sova-section-container_article-tagsCloud-container">
            <h5 className="sova-section-child sova-section-child_article-formHeading sova-section-child_article-form">Tag cloud</h5>
            <div className="sova-section-container_row sova-section-container_article-tagsCloud">
              {tags.edges.map(({ node }: any, key: number) => (
                <span
                  className={`sova-section_article-tag sova-section_article-tag-${key}`}
                  onClick={() => {
                    dispatch("selectPostTag", node.name)
                    navigate(`${locale}/blog`)
                  }}
                  key={key}
                >
                  {node.name}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      {mailValidation && (
        <Modal>
          <div className="sova-section-modal_header">
            <span className="sova-subscribe-form-validationStatus">{mailValidationStatus.toUpperCase()}</span>
            <button onClick={() => changeMailValidationMsg("")} className="sova-section-modal_closeBtn">
              <img src={closeIcon} alt="close icon for modal - sova.ai" />
            </button>
          </div>
          <span className="sova-subscribe-form-validationMsg">{mailValidation}</span>
        </Modal>
      )}
    </Section>
  )
}

ArticleSectionSecond.displayName = "ArticleSectionSecond"

export default ArticleSectionSecond
