import React from "react"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import syntaxTheme from "./syntaxHighlighterTheme"

const CodeSyntaxHighlighter = ({ value }: { value: any }) => {
  return (
    <SyntaxHighlighter language="javascript" style={syntaxTheme}>
      {value}
    </SyntaxHighlighter>
  )
}

export default CodeSyntaxHighlighter
