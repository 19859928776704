import React from "react"
import { graphql } from "gatsby"
import BlogLayout from "../blog/layout/blogLayout"
import ArticleSections from "./article/articleSections"
// import { usePageContext } from "../components/pageContext/pageContext"

const Article = ({ data, pageContext }: any) => {
  const pageContent = data.strapiArticle.content.find((el: any) => {
    return el.language === pageContext.lang
  })

  return (
    <BlogLayout>
      <ArticleSections data={{ ...pageContent, tags: data.allStrapiBlogTag, pageContext: pageContext }} />
    </BlogLayout>
  )
}

export default Article

export const query = graphql`
  query Article($id: String) {
    strapiArticle(id: { eq: $id }) {
      id
      content {
        content
        id
        language
        previewText
        mainImg {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        previewImg {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        author {
          name
          position
          short_bio
          authorImg {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        postParent
        postParentUrl
        blog_tags {
          name
        }
        link
      }
    }
    allStrapiBlogTag {
      edges {
        node {
          name
        }
      }
    }
  }
`
